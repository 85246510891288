var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',[_c('div',{staticClass:"background"}),_c('div',{staticClass:"top_part",class:{iPhoneMap:_vm.terminal === 'iPhone', AndroidMap:_vm.terminal === 'Android'},attrs:{"id":"googlemap","scroll.passive.stop":""},on:{"&touchmove":function($event){$event.stopPropagation();}}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":16,"map-type-id":"roadmap","options":{
         zoomControl: true,
         mapTypeControl: false,
         scaleControl: false,
         streetViewControl: false,
         rotateControl: true,
         fullscreenControl: false,
         disableDefaultUI: false,
         gestureHandling: 'greedy',
         keyboardShortcuts: false
      }}},[_c('GmapMarker',{key:_vm.markSelf.id,attrs:{"position":_vm.markSelf.position,"clickable":false,"draggable":false,"icon":_vm.markSelf.icon,"visible":_vm.markSelf.visible}}),_c('GmapMarker',{key:_vm.markDanjiri.id,attrs:{"position":_vm.markDanjiri.position,"clickable":false,"draggable":false,"icon":_vm.markDanjiri.icon,"visible":_vm.markDanjiri.visible}}),_c('GmapMarker',{attrs:{"position":_vm.hutData.position,"clickable":false,"draggable":false,"icon":_vm.hutData.icon,"visible":_vm.hutVisible}}),_vm._l((_vm.Facilities),function(Facility,index){return _c('GmapMarker',{key:index,attrs:{"position":Facility.position,"clickable":false,"draggable":false,"icon":_vm.FacilityIcons[Facility.category],"visible":_vm.FacilityVisible}})})],2)],1),_c('div',{staticClass:"lower_part",class:{iPhoneContent:_vm.terminal === 'iPhone', AndroidContent:_vm.terminal === 'Android'}},[_c('div',{staticClass:"content"},[_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.hutPush()}}},[_c('div',{staticStyle:{"padding":"1%"}},[_c('div',{staticClass:"hut"},[_c('v-ons-icon',{attrs:{"id":"hut","icon":"md-home","size":"40px"}})],1)])])]),_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.FacilityPush()}}},[_c('div',{staticStyle:{"padding":"1%"}},[_c('div',{staticClass:"temporaryToilet"},[_c('v-ons-icon',{attrs:{"id":"male","icon":"md-male-alt","size":"40px"}}),_c('v-ons-icon',{attrs:{"id":"female","icon":"md-female","size":"40px"}})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }