// ******************************
// ページコントロールMixinファイル
// ******************************
import Vue from 'vue';

//top
import top from '@/components/Views/Top';

//main
import main from '@/components/Views/Main';
import select from '@/components/Views/Select';
import allNews from '@/components/Views/AllNews';
import allSponsor from '@/components/Views/AllSponsor';
import schedule from '@/components/Views/Schedule';
import goodsSelection from '@/components/Views/SalesManagement/GoodsSelection';

//sales management
import userInformation from '@/components/Views/SalesManagement/UserInformation';
import goodsDetails from '@/components/Views/SalesManagement/GoodsDetails';
import checkDetails from '@/components/Views/SalesManagement/CheckDetails';
import revision from '@/components/Views/SalesManagement/revision';
import orderComplete from '@/components/Views/SalesManagement/OrderComplete';

import menu from '@/components/Views/Menu';
import notification from '@/components/Views/Notification';
import newsedit from '@/components/Views/NewsEdit';
import description from '@/components/Views/Description';
import alltownsmap from '@/components/Views/AllTownsMap';
import alltownsmapbox from '@/components/Views/AllTownsMapBox';
import newArrivals from '@/components/Views/NewArrivals';
import newsDetail from '@/components/Views/NewsDetail';
import sponsorList from '@/components/Views/SponsorList';
import sponsorDetail from '@/components/Views/SponsorDetail';
import sponsorDetailTab from '@/components/Views/SponsorDetail';
import categorySelect from '@/components/Views/CategorySelect';
//tabs
import tabmain from '@/components/Views/TabMain';
import course from '@/components/Views/Tabs/DanjiriCourse';
import baseinfo from '@/components/Views/Tabs/BaseInfo';
import news from '@/components/Views/Tabs/News';
import map from '@/components/Views/Tabs/Map';
import mapbox from '@/components/Views/Tabs/MapBox';
import sponsorlist from '@/components/Views/Tabs/SponsorList';
//布団太鼓専用tab
import futontab from '@/components/Views/FutonTab';
import futonnews from '@/components/Views/dedicatedPagesForFutonDrums/News';
import futoninfo from '@/components/Views/dedicatedPagesForFutonDrums/Info';
import futongoods from '@/components/Views/dedicatedPagesForFutonDrums/Goods';
//splitter
import admin from '@/components/Views/Administrator';
import riyoukiyaku from '@/components/Views/Riyoukiyaku';
import favorite from '@/components/Views/Favorite';
import inquiry from "@/components/Views/inquiry";
import inquiryConfirmation from "@/components/Views/InquiryConfirmation"
import inquiryComplete from "@/components/Views/InquiryComplete";
import guide from "@/components/Views/Guide";

// ***** Appで使用するコンポーネントをすべて記載してください ****
const PageList = [
    {
        Key: "description",
        Title: "機能説明",
        Component: description,
        PrevKey: null,
        Icon: null
    },
    {
        Key: "top",
        Title: "",
        Component: top,
        PrevKey: null,
        Icon: null
    },
    {
        Key: "main",
        Title: "",
        Component: main,
        PrevKey: "top",
        Icon: null
    },
    {
        Key: "select",
        Title: "連合選択",
        Component: select,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "allNews",
        Title: "全地域 お知らせ",
        Component: allNews,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "allSponsor",
        Title: "協賛スポンサー一覧",
        Component: allSponsor,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "schedule",
        Title: "日程表",
        Component: schedule,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "favorite",
        Title: "お気に入り",
        Component: favorite,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "categorySelect",
        Title: "スポンサー",
        Component: categorySelect,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "newsDetail",
        Title: "お知らせ",
        Component: newsDetail,
        PrevKey: "allNews",
        Icon: null
    },
    {
        Key: "menu",
        Title: "メニュー",
        Component: menu,
        PrevKey: "select",
        Icon: null
    },
    {
        Key: "newArrivals",
        Title: "運営からのお知らせ",
        Component: newArrivals,
        PrevKey: "top",
        Icon: null
    },
    {
        Key: "notification",
        Title: "町お知らせ一覧",
        Component: notification,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "alltownsmap",
        Title: "現在地",
        Component: alltownsmap,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "alltownsmapbox",
        Title: "現在地",
        Component: alltownsmapbox,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "sponsorList",
        Title: "スポンサー",
        Component: sponsorList,
        PrevKey: "categorySelect",
        Icon: null
    },
    {
        Key: "sponsorDetail",
        Title: "スポンサー",
        Component: sponsorDetail,
        PrevKey: "sponsorList",
        Icon: null
    },
    {
        Key: "sponsorDetailTab",
        Title: "スポンサー",
        Component: sponsorDetailTab,
        PrevKey: "tab",
        Icon: null
    },
    {
        Key: "newsedit",
        Title: "お知らせ編集",
        Component: newsedit,
        PrevKey: "tab",
        Icon: null
    },
    //tabs
    {
        Key: "tab",
        Title: "タブ画面",
        Component: tabmain,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "map",
        Title: "地車現在地",
        Component: map,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "mapbox",
        Title: "地車現在地",
        Component: mapbox,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "course",
        Title: "コース",
        Component: course,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "baseinfo",
        Title: "基本情報",
        Component: baseinfo,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "sponsorlist",
        Title: "スポンサー一覧",
        Component: sponsorlist,
        PrevKey: "tab",
        Icon: null
    },
    {
        Key: "news",
        Title: "お知らせ",
        Component: news,
        PrevKey: "menu",
        Icon: null
    },
    //
    {
        Key: "futontab",
        Title: "百舌鳥連合青年団",
        Component: futontab,
        PrevKey: "menu",
        Icon: null
    },
    {
        Key: "futongoods",
        Title: "物販一覧",
        Component: futongoods,
        PrevKey: "futontab",
        Icon: null
    },
    {
        Key: "futoninfo",
        Title: "基本情報",
        Component: futoninfo,
        PrevKey: "futontab",
        Icon: null
    },
    {
        Key: "futonnews",
        Title: "お知らせ",
        Component: futonnews,
        PrevKey: "futontab",
        Icon: null
    },
    //splitter
    {
        Key: "admin",
        Title: "管理者用",
        Component: admin,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "riyoukiyaku",
        Title: "利用規約",
        Component: riyoukiyaku,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "guide",
        Title: "ガイド",
        Component: guide,
        PrevKey: "main",
        Icon: null
    },
    //お問い合わせ
    {
        Key: "inquiry",
        Title: "問い合わせ",
        Component: inquiry,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "inquiryConfirmation",
        Title: "お問い合わせ確認",
        Component: inquiryConfirmation,
        PrevKey: "inquiry",
        Icon: null
    },
    {
        Key: "inquiryComplete",
        Title: "お問い合わせ確認",
        Component: inquiryComplete,
        PrevKey: null,
        Icon: null
    },

    //sales management
    {
        Key: "goodsSelection",
        Title: "グッズ選択",
        Component: goodsSelection,
        PrevKey: "main",
        Icon: null
    },
    {
        Key: "goodsDetails",
        Title: "グッズ詳細",
        Component: goodsDetails,
        PrevKey: "goodsSelection",
        Icon: null
    },
    {
        Key: "userInformation",
        Title: "ユーザー情報入力",
        Component: userInformation,
        PrevKey: "goodsDetails",
        Icon: null
    },
    {
        Key: "checkDetails",
        Title: "グッズ確認",
        Component: checkDetails,
        PrevKey: "userInformation",
        Icon: null
    },
    {
        Key: "revision",
        Title: "修正",
        Component: revision,
        PrevKey: "checkDetails",
        Icon: null
    },
    {
        Key: "orderComplete",
        Title: "注文完了",
        Component: orderComplete,
        PrevKey: null,
        Icon: null
    },
];

//タブ画面用タイトルアイコンデータ
const TitleIcon4Tab = {
    Title: null,
    Icon: null
}

// ***** サブメニューに表示するコンポーネントを記載してください *****
const SubMenuList = [
    {
        Key: "sm001",
        MenuTitle: "お気に入り",
        RelationPageList: "favorite"
    },
    // {
    //     Key: "sm002",
    //     MenuTitle: "運営からのお知らせ",
    //     RelationPageList: "newArrivals"
    // },
    {
        Key: "sm003",
        MenuTitle: "お問い合わせ",
        RelationPageList: "inquiry"
    },
    {
        Key: "sm004",
        MenuTitle: "ガイド",
        RelationPageList: "guide"
    },
    {
        Key: "sm005",
        MenuTitle: "利用規約",
        RelationPageList: "riyoukiyaku"
    },
    {
        Key: "sm006",
        MenuTitle: "各町管理者用",
        RelationPageList: "admin"
    },
];

// ***** タブに表示するコンポーネントを記載してください *****
const TabList = [   
    // {
    //     icon: null,
    //     label: '地車現在地',
    //     RelationPageList: "map",
    //     props: {
    //         myProp: 'This is a page prop!'
    //     },
    //     key: null,      //null固定
    //     page:null,      //null固定
    // },
    {
        icon: null,
        label: '地車現在地',
        RelationPageList: "mapbox",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
    {
        icon: null,
        label: 'コース',
        RelationPageList: "course",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
    {
        icon: null,
        label: '基本情報',
        RelationPageList: "baseinfo",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
    {
        icon: null,
        label: 'スポンサー',
        RelationPageList: "sponsorlist",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
    {
        icon: null,
        label: 'お知らせ',
        RelationPageList: "news",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
];

const FutonTabList = [
    {
        icon: null,
        label: '団長紹介',
        RelationPageList: "futoninfo",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
    {
        icon: null,
        label: '物販',
        RelationPageList: "futongoods",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
    {
        icon: null,
        label: 'お知らせ',
        RelationPageList: "futonnews",
        props: {
            myProp: 'This is a page prop!'
        },
        key: null,      //null固定
        page:null,      //null固定
    },
];

// ***** ページ制御に関するグローバル用変数 *****
let mixPC_Global = new Vue({
    data: {
        // 表示するコンポーネントオブジェクト
        $mixPC_CurrentPage: {
            Title: "",
            Component: null,
            PrevComponent: null
        },
        $mixPC_ActiveBottomTabIndex: 0,
        $mixPC_ActiveTabIndex: 0,
        $_mixPC_TitleIcon4Tab: TitleIcon4Tab
    }
});

export const mixPageController = {
    data () {
        return {
            mixPC_SubMenuList: SubMenuList
        }
    },
    methods: {
        // **** グローバル変数プロパティ ****
        //各コンポーネント内の<script>タグ内でグローバル変数を参照するための関数

        //現在表示するコンポーネント情報プロパティ
        $_mixPC_CurrentPage(){
            return mixPC_Global.$data.$mixPC_CurrentPage
        },
        $_SetmixPC_CurrentPage(val) {
            mixPC_Global.$data.$mixPC_CurrentPage = val
        },

        //PageList取得プロパティ
        $_mixPC_PageList(){
            return PageList
        },

        //タブ用タイトルアイコン
        $_mixPC_TitleIcon4Tab(){
            return mixPC_Global.$data.$_mixPC_TitleIcon4Tab
        },
        $_SetmixPC_TitleIcon4Tab(val){
            mixPC_Global.$data.$_mixPC_TitleIcon4Tab = val
        },

        // タブメニューオブジェクトを生成
        $_mixPC_Tabmenu_init(){
            var rtnTabMenu = [];
            TabList.forEach(function(value){
                let pl = PageList.filter(e => e.Key === value.RelationPageList);
                value.key = pl[0].Key;
                value.page = pl[0].Component;
                rtnTabMenu.push(value);
            });
            return rtnTabMenu;
        },
        // タブメニューオブジェクトを生成
        $_mixPC_FutonTabmenu_init(){
            var rtnTabMenu = [];
            FutonTabList.forEach(function(value){
                let pl = PageList.filter(e => e.Key === value.RelationPageList);
                value.key = pl[0].Key;
                value.page = pl[0].Component;
                rtnTabMenu.push(value);
            });
            return rtnTabMenu;
        },

        //Tabページ内のページ制御
        $_mixPC_ActiveTabIndex(){
            return mixPC_Global.$data.$mixPC_ActiveTabIndex
        },
        $_SetmixPC_ActiveTabIndex(val){
            mixPC_Global.$data.$mixPC_ActiveTabIndex = val
        },

        // ****** 独自関数 *****
        //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
        $_SetmixPC_CurrentPage_Key(val){
            //もしタブページの場合はタイトル、アイコンを保持するために個別処理

            //PageListを取得
            let PageL = this.$_mixPC_PageList();
            let CurComponent = PageL.filter(e => e.Key === val);
            if (CurComponent.length <= 0) {
                return false
            }

            if (val == 'tab'){
                let tabtitle = this.$_mixPC_TitleIcon4Tab()
                CurComponent[0].Title = tabtitle.Title
                CurComponent[0].Icon = tabtitle.Icon
            }
            this.$_SetmixPC_CurrentPage(CurComponent[0]);

            return true;
        },

    },
    computed: {

        // **** グローバル変数プロパティ ****
        //各コンポーネント内の<template>タグ内でグローバル変数を参照するための処置

        //現在表示するコンポーネント情報プロパティ
        $mixPC_CurrentPage: {
            get: function () { return mixPC_Global.$data.$mixPC_CurrentPage },
            set: function (val) { mixPC_Global.$data.$mixPC_CurrentPage = val}
        },
        $mixPC_PageList: {
            get: function () { return PageList }
        },
        //PageList取得プロパティ

        // **** 独自computed *****

        // 初回ページコンポーネントを$mixPC_CurrentPageへセット
        mixPC_firstPageSet: function () {
            let jConfig = this.mixCF_config;
            let firstKey = jConfig[0].firstPageKey; //1ページ目のKeyをconfigから取得
            let fistObject = PageList.filter(e => e.Key === firstKey);  //configから取得した初回ページタイトルのデータをフィルタ
            this.$_SetmixPC_CurrentPage(fistObject[0]);
            return true;
        },
        //現在のコンポーネントから前画面のコンポーネントをセットする
        mixPC_PrevComponentToCurrntPage: function () {
            let curr = this.$_mixPC_CurrentPage();    //現在のページコンポーネントを取得

            let prv = PageList.filter(e => e.Key === curr.PrevKey);
            //戻るページがタブの場合、タイトル、アイコンを保持するために個別処理
            if (prv[0].Key == 'tab'){
                let tabtitle = this.$_mixPC_TitleIcon4Tab()
                prv[0].Title = tabtitle.Title
                prv[0].Icon = tabtitle.Icon
            }

            this.$_SetmixPC_CurrentPage(prv[0]);
            return true;
        }
    },
}