<template>
    <v-ons-page>

        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <img src="img/banner/sponsor.jpg" style="width: 100%;">

        <div id="explanation">
            <v-ons-row>
                <v-ons-col width="100%">
                    以下からクーポンのあるお店を探す
                </v-ons-col>
                <v-ons-col width="100%">
                    ※クーポンは準備中の店舗もございます。
                </v-ons-col>
            </v-ons-row>
        </div>

<!--        <div style="overflow: hidden;border-radius: 20px;margin: 5px 15px 20px;">-->
        <div>
            <div class="category" v-for="(large,index) in res" :key="index">
                <input :id="'acd-check'+index" class="acd-check" type="checkbox">
                <label class="acd-label" :for="'acd-check'+index">{{ large.large_category_name }}<ons-icon class="icon" size="20px" icon="fa-sort-down"></ons-icon></label>
                <div class="acd-content">
                    <div class="category_data" v-for="(small, index) in large.small_category_data" :key="index">
                        <div class="small_category" @click="sponsorList(small.small_category_id)">
                            <img class="img" :src="small.small_category_img">
                            <div style="font-family: Sawarabi Mincho, Avenir, Helvetica, Arial, sans-serif;">{{ small.small_category_name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "CategorySelect",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar
        },
        data() {
            return {
                isExpanded: false,
                res: [],
                modalVisible: false,
                showIMGPath: '',
            }
        },
        mounted(){
            let self = this
            let category = this.$_mixAPI_getCategory()
            category.then(function(val){
                val.forEach(function(category){
                    self.res.push(category)
                })
            })
        },
        methods: {
            sponsorList(val){
                this.$_setPage('')
                return(
                    this.$_setCategoryID(val),
                    //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                    this.$_SetmixPC_CurrentPage_Key('sponsorList')
                );
            }
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .category{
        margin: 0;
    }
    .acd-label {
        color: white;
        display: block;
        padding: 5% 0;
        width: 99%;
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        background-image: url("/img/bg_btn.jpg");
        background-position: center;
        background-size: 120%;
        border: 2px solid #b58012;
        margin: 0;
        position: relative;
    }
    .acd-check {
        display: none;
    }
    .acd-content {
        width: 100%;
        height: 0;
        opacity: 0;
        transition: .5s;
        visibility: hidden;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }
    .acd-check:checked + .acd-label + .acd-content {
        height: auto;
        opacity: 1;
        visibility: visible;
    }
    .icon {
        text-align: right;
        top: -2px;
    }
    .img{
        width: 35%;
        margin-bottom: 5%;
    }
    .small_category{
        width: 100%;
        padding: 15% 1% 5% 1%;
        border: 1px solid #b58012;
        justify-content: center;
        color: white;
        text-align: center;
        background-color: black;
    }
    #explanation{
        color: white;
        margin: 5%;
    }
    .category_data{
        display: flex;
        width: 33.3%;
    }
</style>