<!--
  Title        : 商品詳細確認
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div style="color: white;text-align: left">
            <h2 class="title">注文内容</h2>
            <div class="title">注文内容にお間違いないかご確認ください。</div>
        </div>

        <div class="main">
            <div class="info">
                <div>商品名</div>
                <div>{{ goods_name }}</div>
            </div>
            <div class="info" v-show="goods_name == 'タオル'">
                <div>生地</div>
                <img :src="selected_towel_material_img">
            </div>
            <div class="info" v-show="goods_name == 'タスキ' ||goods_name == '巾着' || goods_name == '法被' || goods_name == '鯉口シャツ' || goods_name == 'チョッキ'">
                <div>生地</div>
                <img :src="selected_material_img">
            </div>
            <div class="info" v-show="goods_name == 'タオル'">
                <div>柄</div>
                <img :src="selected_pattern_img">
            </div>
            <div class="info" v-show="goods_name == 'タスキ'">
                <div>加工方法</div>
                <img :src="selected_machining_img">
            </div>
            <div class="info" v-show="goods_name == '団扇'">
                <div>種類</div>
                <img :src="selected_fan_img">
            </div>
            <div class="info" v-show="goods_name == '団扇'">
                <div>{{ type }}</div>
            </div>
            <div class="info" v-show="goods_name == 'タスキ房'">
                <div>色数</div>
                <div>{{ color }}</div>
            </div>
            <div class="info" v-show="goods_name == '法被' || goods_name == '鯉口シャツ' || goods_name == 'チョッキ' || goods_name == 'ロンパース' || goods_name == 'タスキ房' || goods_name == '団扇'">
                <div>サイズ</div>
                <div>{{ size }}</div>
            </div>
            <div class="info">
                <div>単価(税抜)  ×  数量</div>
                <div>{{ base }}円  ×  {{ volume }}</div>
            </div>
            <div class="info">
                <div>総額(税抜)</div>
                <div>{{ amount }}円</div>
            </div>
        </div>

        <div class="main">
            <div class="info">
                <div>氏名</div>
                <div>{{ name }}</div>
            </div>
            <div class="info">
                <div>性別</div>
                <div>{{ gender }}</div>
            </div>
            <div class="info">
                <div>生年月日</div>
                <div>{{ year }}年{{ month }}月{{ day }}日</div>
            </div>
            <div class="info">
                <div>郵便番号</div>
                <div>{{ postal_code }}</div>
            </div>
            <div class="info">
                <div>住所</div>
                <div>{{ prefecture }}{{ town }}{{ city }}{{ building }}</div>
            </div>
            <div class="info">
                <div>メールアドレス</div>
                <div>{{ email }}</div>
            </div>
            <div class="info">
                <div>使用目的</div>
                <div>{{ intended_use_radio }}</div>
                <div>{{ intended_use }}</div>
            </div>

            <div>
                <v-ons-button class="decision" @click="OrderComplete()">注文確定</v-ons-button>
<!--                <v-ons-button class="revision" @click="PageTransition('revision')">修正</v-ons-button>-->
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from '../../Common/Toolbar';
    import BottomToolbar from "../../Common/BottomToolbar";

    export default {
        name: "CheckDetails",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data(){
            return{
                goods_name: this.$_GoodsDetail().goods_name,
                type: this.$_GoodsDetail().type,
                volume: this.$_GoodsDetail().volume,
                base: this.$_GoodsDetail().base,
                amount: this.$_GoodsDetail().amount,
                color: this.$_GoodsDetail().color,
                size: this.$_GoodsDetail().size,
                selected_towel_material: this.$_GoodsDetail().selected_towel_material,
                selected_towel_material_img: this.$_GoodsDetail().selected_towel_material_img,
                selected_material: this.$_GoodsDetail().selected_material,
                selected_material_img: this.$_GoodsDetail().selected_material_img,
                selected_pattern: this.$_GoodsDetail().selected_pattern,
                selected_pattern_img: this.$_GoodsDetail().selected_pattern_img,
                selected_machining: this.$_GoodsDetail().selected_machining,
                selected_machining_img: this.$_GoodsDetail().selected_machining_img,
                selected_fan: this.$_GoodsDetail().selected_fan,
                selected_fan_img: this.$_GoodsDetail().selected_fan_img,

                name: this.$_UserInfo().name,
                gender: this.$_UserInfo().gender,
                email: this.$_UserInfo().email,
                year: this.$_UserInfo().year,
                month:this.$_UserInfo().month,
                day: this.$_UserInfo().day,
                postal_code: this.$_UserInfo().postal_code,
                prefecture: this.$_UserInfo().prefecture,
                city: this.$_UserInfo().city,
                town: this.$_UserInfo().town,
                building: this.$_UserInfo().building,
                intended_use_radio: this.$_UserInfo().intended_use_radio,
                intended_use_text: this.$_UserInfo().intended_use_text,
            }
        },
        created() {
            this.amount = this.amount.toLocaleString()
        },
        methods:{
            OrderComplete(){
                let self = this
                //データ登録
                //ユーザー情報
                let purchasers = {
                    device_token: this.$_deviceToken(),
                    name: this.name,
                    gender: this.gender,
                    email: this.email,
                    birth_day: this.year + '-' + this.month + '-' + this.day,
                    postal_code: this.postal_code,
                    prefecture: this.prefecture,
                    city: this.city,
                    town: this.town,
                    building: this.building,
                    intended_use_type: this.intended_use_radio,
                    intended_use_text: this.intended_use_text,
                }
                let getPurchasersResultAPI = this.$_mixAPI_registPurchasers(purchasers)
                getPurchasersResultAPI.then(function(val){
                    //商品詳細
                    let productDetails = {
                        purchasers_id: val,
                        name: self.name,
                        email: self.email,
                        goods_name:self.goods_name,
                        volume:self.volume,
                        amount:self.amount,
                        color: self.color,
                        size: self.size,
                        towel_material: self.selected_towel_material,
                        material: self.selected_material,
                        pattern: self.selected_pattern,
                        machining: self.selected_machining,
                        fan: self.selected_fan,
                        type: self.type
                    }
                    let getProductDetailsResultAPI = self.$_mixAPI_registProductDetails(productDetails)
                    getProductDetailsResultAPI.then(function(){
                    })
                })

                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === 'orderComplete');
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            },
            PageTransition(val){
                //PageListを取得
                let PageL = this.$_mixPC_PageList();
                let CurComponent = PageL.filter(e => e.Key === val);
                this.$_SetmixPC_CurrentPage(CurComponent[0]);
            },
        }
    }
</script>

<style scoped>
    img {
        width: 100%;
    }
    h2 {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    }
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .title {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        margin: 3% 0 1% 5%;
        padding: 0;
    }
    .main {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #f4f4f4;
        text-align: left;
        border-radius: 15px;
        margin: 5%;
        padding: 5%;
    }
    .info {
        margin: 1% 0 5% 0;
    }
    .decision {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: white;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        position: center;
        text-align: center;
        width: 90%;
        margin: 5%;
        padding: 3%;
    }
    .revision {
        font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: black;
        background-color: #9e9e9e;
        box-shadow: 3px 4px 3px rgba(0, 0, 0, 0.1);
        text-align: center;
        width: 50%;
        margin: 5% 25% 5% 25%;
        padding: 3%;
    }
</style>