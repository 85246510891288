<!--
  Title        : スプリッター
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-splitter>
    <v-ons-splitter-side
        width="250px" collapse="" side="right"
        :open.sync="openSide"
    >
      <v-ons-page>
        <v-ons-list>
          <v-ons-list-item v-for="(page, index) in mixPC_SubMenuList" :key="index"
                           tappable modifier="chevron"
                           @click="MenuClick(page); openSide = false"
          >
            <div class="center">
              {{ page.MenuTitle }}
            </div>
          </v-ons-list-item>
        </v-ons-list>

        <!--パス確認ダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertPassVisible">
          管理者用のパスワードを<br>
          入力してください
          <div class="center">
            <v-ons-input placeholder="パスワードを入力" float
                         id="adminPassword" />
          </div>
          <div class="dialogbtn">
            <v-ons-alert-dialog-button @click="alertPassVisible = false">
              キャンセル
            </v-ons-alert-dialog-button>
            <v-ons-alert-dialog-button @click="Admin">
              ログイン
            </v-ons-alert-dialog-button>
          </div>
        </v-ons-alert-dialog>

        <!--エラーダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertErrPassVisible">
          <span slot="title">エラー</span>
          {{ ErrMessage }}
          <template slot="footer">
            <v-ons-alert-dialog-button @click="alertErrPassVisible = false">Ok</v-ons-alert-dialog-button>
          </template>
        </v-ons-alert-dialog>

        <!--  ローディングダイアログ-->
        <v-ons-alert-dialog modifier="rowfooter"
                            :visible.sync="alertLoadingVisible">
          <Spinner message="データ取得中...." />
        </v-ons-alert-dialog>

      </v-ons-page>
    </v-ons-splitter-side>

    <v-ons-splitter-content>
      <component :is="$mixPC_CurrentPage.Component" :toggle-menu="() => openSide = !openSide"></component>
    </v-ons-splitter-content>
  </v-ons-splitter>
</template>

<script>
import Spinner from 'vue-simple-spinner';

export default {
  name: "Splitter",
  components: {
    Spinner,
  },
  data() {
    return{
      openSide: false,
      alertPassVisible: false,
      alertErrorVisible: false,
      alertErrPassVisible: false,
      alertLoadingVisible: false,
      ErrMessage: '',
      RPage: '',
      unreadFlg: true,
      unreadNenbanFlg: true,
      notification: false
    }
  },
  computed:{
    validation: function(){
      let self = this
      return self.unreadFlg = this.$_UnreadCountFlg()
    },
    nenbanValidation: function(){
      let self = this
      return self.unreadNenbanFlg = this.$_UnreadNenbanCountFlg()
    },
  },
  methods:{
    MenuClick: function (val) {
      //クリックされたサブメニューのRelationPageList取得
      this.RPage = val.RelationPageList;
      if(this.RPage == 'admin'){
        this.alertPassVisible = true
        return
      }
      //PageListを取得
      let PageL = this.$_mixPC_PageList();
      let CurComponent = PageL.filter(e => e.Key === this.RPage);
      this.$_SetmixPC_CurrentPage(CurComponent[0]);
    },
    Admin: function(){
      let self = this
      let inputValue = document.getElementById("adminPassword").value;
      let params = { password: inputValue };
      let checkPassAPI = this.$_mixAPI_checkAdminPassword(params)
      //---パス未入力チェック---
      if(inputValue == ''){
        self.ErrMessage = "パスワードを入力してください。";
        self.alertErrPassVisible = true;                     //エラーダイアログを非表示
        return;
      }
      checkPassAPI.then(function(val){
        if(val.result == false){
          self.ErrMessage = "パスワードが違います。";
          self.alertErrPassVisible = true;
          document.getElementById("adminPassword").value = '';     //入力値をクリア
          return;
        }
        if(val.Town){
          self.$_setAdTownID(val.Town.town_id)
          self.$_setAdFestivalID(val.Town.festival_id)
          self.$_setPlaceName(val.Town.town_name)
          self.$_setPage('News')
        }else{
          self.$_setAdFestivalID(val.Festival.festival_id)
          self.$_setPlaceName(val.Festival.festival_name)
          self.$_setPage('NenbanNews')
        }
        //PageListを取得
        let PageL = self.$_mixPC_PageList();
        let CurComponent = PageL.filter(e => e.Key === self.RPage);
        self.$_SetmixPC_CurrentPage(CurComponent[0]);
      }).catch((err)=>{   //通信エラー時
        console.log(err);
        self.alertErrorVisible = true;              //エラーダイアログ表示
      }).finally(()=>{
        document.getElementById("adminPassword").value = '';
        self.alertPassVisible = false;
      });
    }
  }
}
</script>

<style scoped>
  .list {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .dialogbtn{
    display: flex;
  }
</style>