<!--
  Title        : ツールバー
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-toolbar>
    <meta name=viewport content="width=device-width,initial-scale=1,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no">
    <div class="left" v-if="$mixPC_CurrentPage.PrevKey != null">
      <v-ons-toolbar-button @click="btnBack">
        <v-ons-icon
            size="20px"
            icon="chevron-left">
        </v-ons-icon>
      </v-ons-toolbar-button>
    </div>
    <div class="left" v-else></div>

    <div class="center">
      <img id="icon" v-if="$mixPC_CurrentPage.Icon != null" :src="$mixPC_CurrentPage.Icon">
      <span v-else></span>
      {{ $mixPC_CurrentPage.Title }}
    </div>

    <div class="right">
      <v-ons-toolbar-button class="favorite" :class="{ iPhoneFavorite:terminal === 'iPhone', AndroidFavorite:terminal === 'Android' }" v-show="isShowFavorite" @click="btnFavorite">
          <img class="icon-img" src="/img/parts/ハート-アイコン.png" v-show="fav == true">
          <img class="icon-img" src="/img/parts/ハート(赤)-アイコン.png" v-show="fav == false">
      </v-ons-toolbar-button>

      <v-ons-toolbar-button id="menu" @click="action">
        <v-ons-icon icon="ion-ios-menu, material:md-menu" size="30px"></v-ons-icon>
      </v-ons-toolbar-button>
    </div>

    <v-ons-alert-dialog modifier="rowfooter"
                        :visible.sync="alertUpdateVisible">
      <span slot="title">お知らせ</span>
      <span>最新バージョンへのアップデートが必要です。</span>
      <template slot="footer">
        <v-ons-alert-dialog-button>
          <a href="https://play.google.com/store/apps/details?id=com.technoeye.jiguruma">こちらからアップデート</a>
        </v-ons-alert-dialog-button>
      </template>
    </v-ons-alert-dialog>

  </v-ons-toolbar>
</template>

<script>
export default {
  name: "Toolbar",
  props: ['toggleMenu','action'],
  data(){
    return{
      isShowFavorite: true,
      //お気に入り登録
      isFavorite: false,
      alertUpdateVisible: false,
      fav: true,
      terminal: this.$_Terminal()
    }
  },
  created() {
    let curView = this.$mixPC_CurrentPage
    let uuid = this.$_uuid()

    if (curView.Key == 'menu' && uuid != ''){
      //お気に入りアイコンが表示されるタブ画面遷移時には町会データがあるはずなので、お気に入りデータ有無を判別する
      let resFesD = this.$_FestivalData();
      resFesD.favorites ? this.isFavorite = true : this.isFavorite = false
    }
    if (curView.Key == 'tab' || curView.Key == 'futontab' || curView.Key == '' && uuid != ''){
      this.isShowFavorite = true
      //お気に入りアイコンが表示されるタブ画面遷移時には町会データがあるはずなので、お気に入りデータ有無を判別する
      let resTownD = this.$_TownData();
      if (resTownD.favorites){
        this.isFavorite = true
        this.fav = false
      } else {
        this.isFavorite = false
        this.fav = true
      }
    }else{
      this.isShowFavorite = false
    }
  },
  mounted() {
    if(this.$_mixPC_CurrentPage().Key == 'alltownsmapbox'){
      switch (this.$_festivalType()) {
        case 1:
          this.$_mixPC_CurrentPage().Title = '地車現在地'
          return
        case 2:
          this.$_mixPC_CurrentPage().Title = '太鼓現在地'
          return
        case 3:
          this.$_mixPC_CurrentPage().Title = '祇園車現在地'
          return
      }
    }
  },
  methods: {
    btnBack: function () {
      switch (this.$_Page()) {
        case 'main':
          this.$_setPage('')
          return this.$_SetmixPC_CurrentPage_Key('main')
        case 'Select':
          this.$_setPage('')
          return this.$_SetmixPC_CurrentPage_Key('select')
        case 'Menu':
          this.$_setPage('')
          return this.$_SetmixPC_CurrentPage_Key('menu')
        case 'AllSponsor':
          this.$_setPage('')
          return this.$_SetmixPC_CurrentPage_Key('allSponsor')
        default:
          return (
              //現在のコンポーネントから前画面を取得し、カレントへセット
              this.mixPC_PrevComponentToCurrntPage
          );
      }
    },
    btnFavorite: function (){
      this.alertLoadingVisible = true   //Loadingダイアログ表示開始
      let self = this   //thenの中からdataを操作するため

      let resTownD = this.$_TownData();
      let apiVal = {
        uuid: this.$_uuid(),
        festival_id: resTownD.festival_id,
        town_id: resTownD.town_id,
        is_regist:0   //解除モード
      }
      if (!this.isFavorite){
        //お気に入り登録モードに変更
        apiVal.is_regist = 1;
      }
      let getResultAPI = self.$_mixAPI_postRegistFavorite(apiVal)
      getResultAPI.then(function(){
        if (self.isFavorite){
          //お気に入り解除後処理
          self.isFavorite = false;
          resTownD.favorites = false;
          self.fav = true
        } else {
          //お気に入り後処理
          self.isFavorite = true;
          resTownD.favorites = true;
          self.fav = false
        }
        self.$_setTownData(resTownD);
      }).catch((err)=>{   //通信エラー時
        console.log(err);
        self.alertErrorVisible = true   //エラーダイアログ表示
      }).finally(()=>{
        self.alertLoadingVisible = false    //Loadingダイアログを非表示
      });
    },
  }
}
</script>

<style scoped>
  .toolbar {
    /*background: url("/img/obi.png");*/
    /*background-size: 130%;*/

    background-image: url("/img/parts/toolbar_background_white.png");
    background-color: white;

    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .toolbar--material{
    background-size: 600px !important;
    height: 56px !important;
  }
  .toolbar-button{
    /*color: white;*/
    color: black;
  }
  .toolbar__center{
    width: calc(100% - 160px) !important;
  }
  .toolbar__left{
    width: 80px !important;
  }
  .toolbar__right{
    width: 80px !important;
  }
  .toolbar--material__center{
    width: calc(100% - 160px) !important;
  }
  .toolbar--material__left{
    width: 80px !important;
  }
  .toolbar--material__right{
    width: 80px !important;
  }
  .center {
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*color: #fdfbfb;*/
    color: black;
    font-size: 20px;
    text-align: center;
  }
  #icon{
    width: 25px;
    height: 25px;
    padding: 0 0 5px;
    vertical-align: middle;
  }
  .favorite{
    font-family: "Sawarabi Mincho", Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background-color: white;
    padding-left: 0;
    padding-right: 0;
    line-height: 20px;
  }
  .iPhoneFavorite{
    margin: 6px 10px 15px 0;
    padding: 0;
  }
  .AndroidFavorite{
    margin: 10px 0;
    padding: 1px 10px 10px 0;
  }
  #menu{
    color: black;
    padding: 0 10px 0 0;
  }
  .icon-img {
    width: 23px;
    height: 23px;
  }
</style>
