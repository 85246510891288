<template>
    <v-ons-page>
        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <div>
            <div class="main-top">
                <p class="shop_name">{{ sponsor.company_name }}</p>
                <img class="banner" :src="sponsor.sponsor_img">
                <carousel :per-page="3" :pagination-enabled="false" :scrollPerPage="false">
                    <slide v-for="(value, index) in sponsor.images" :key=index>
                        <viewer class="viewer">
                            <img class="menu_img" :src="value">
                        </viewer>
                    </slide>
                </carousel>
            </div>

            <div class="main">
                <ul class="tabs">
                    <li class="tab" @click="change(1)" v-bind:class="{'active' : isActive === 1}">クーポン</li>
                    <li class="tab" @click="change(2)" v-bind:class="{'active' : isActive === 2}">基本情報</li>
                    <li class="tab" @click="change(3)" v-bind:class="{'active' : isActive === 3}">{{ couponRecommendName }}</li>
                </ul>

                <ul class="contents">
                    <li class="coupon" v-show="isActive === 1">
                        <div v-for="(coupon, index) in coupon" :key="index">
                            <div class="coupon_main">
                                <div class="left">
                                    <div class="left_cont">
                                        <p class="store">
                                            <i class="fas fa-store"></i>
                                            {{ companyName }}
                                        </p>
                                        {{ coupon.value.coupon_name }}
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="right_cont">
                                        <span>有効期限</span>
                                        <br>{{ coupon.value.effective_date }}
                                    </div>
                                </div>
                            </div>
                            <div class="coupon_contents">
                                <p class="coupon_contents_name">
                                    <span>利用条件</span>
                                    <span>：</span>
                                    <span>{{ coupon.value.terms_use }}</span>
                                </p>
                                <p class="coupon_contents_name">
                                    <span>提示条件</span>
                                    <span>：</span>
                                    <span>{{ coupon.value.terms_suggest }}</span>
                                </p>
                                <p class="coupon_contents_name">
                                    <span>備考</span>
                                    <span>：</span>
                                    <span>{{ coupon.value.remarks }}</span>
                                </p>
                            </div>
                        </div>
                    </li>

                    <li v-show="isActive === 2">
                        <div ref="company_contents" class="company_contents">
                            <p class="company_contents_title"><span class="verticalLine"></span>お店のサイト</p>
                            <div class="sns_icon">
                                <a class="sns_url" v-show="sponsor.web_url" :href="sponsor.web_url" target="_blank">
                                    <img class="sns_icon" src="/img/sns_icon/homepage_icon.png">
                                    <p  class="sns_name">ホーム<br>ページ</p>
                                </a>
                                <a class="sns_url" v-show="sponsor.facebook_url" :href="sponsor.facebook_url" target="_blank">
                                    <img class="sns_icon" src="/img/sns_icon/facebook_icon.png">
                                    <p class="sns_name">facebook</p>
                                </a>
                                <a class="sns_url" v-show="sponsor.instagram_url" :href="sponsor.instagram_url" target="_blank">
                                    <img class="sns_icon" src="/img/sns_icon/instagram_icon.png">
                                    <p class="sns_name">instagram</p>
                                </a>
                                <a class="sns_url" v-show="sponsor.twitter_url" :href="sponsor.twitter_url" target="_blank">
                                    <img class="sns_icon" src="/img/sns_icon/X_icon.png">
                                    <p class="sns_name">X</p>
                                </a>
                                <a class="sns_url" v-show="sponsor.tiktok_url" :href="sponsor.tiktok_url" target="_blank">
                                    <img class="sns_icon" src="/img/sns_icon/tiktok_icon.png">
                                    <p class="sns_name">tiktok</p>
                                </a>
                                <a class="sns_url" v-show="sponsor.youtube_url" :href="sponsor.youtube_url" target="_blank">
                                    <img class="sns_icon" src="/img/sns_icon/youtube_icon.png">
                                    <p class="sns_name">youtube</p>
                                </a>
                            </div>
                            <p class="company_contents_title"><span class="verticalLine"></span>会社紹介</p>
                            <p class="introduction">{{ sponsor.introduction }}</p>
                            <p class="company_contents_title"><span class="verticalLine"></span>住所</p>
                            <p class="address">{{ sponsor.address1 }}</p>
                            <div ref="mapContainer" class="map-container"></div>
<!--                            <div class="map">-->
<!--                                <GmapMap-->
<!--                                        :center="center"-->
<!--                                        :zoom="16"-->
<!--                                        map-type-id="roadmap"-->
<!--                                        style="width: 100%; height: 300px"-->
<!--                                        :options="{-->
<!--                                        zoomControl: false,-->
<!--                                        mapTypeControl: false,-->
<!--                                        scaleControl: false,-->
<!--                                        streetViewControl: false,-->
<!--                                        rotateControl: true,-->
<!--                                        fullscreenControl: false,-->
<!--                                        disableDefaultUI: false,-->
<!--                                        gestureHandling: 'greedy',-->
<!--                                    }"-->
<!--                                >-->
<!--                                    <GmapMarker-->
<!--                                            :position="center"-->
<!--                                            :clickable="false"-->
<!--                                            :draggable="false"-->
<!--                                    />-->
<!--                                </GmapMap>-->
<!--                            </div>-->
                            <p class="company_contents_title"><span class="verticalLine"></span>アクセス</p>
                            <p class="access">{{ sponsor.access }}</p>
                            <p class="company_contents_title"><span class="verticalLine"></span>電話番号/FAX</p>
                            <p class="tel">{{ sponsor.tel }} <span v-show="sponsor.fax">/</span> {{ sponsor.fax }}</p>
                            <p class="company_contents_title"><span class="verticalLine"></span>営業時間</p>
                            <p class="business_hours">{{ sponsor.business_hours }}</p>
                            <p class="company_contents_title"><span class="verticalLine"></span>定休日</p>
                            <p class="regular_holiday">{{ sponsor.regular_holiday }}</p>
                        </div>
                    </li>

                    <li v-show="isActive === 3">
                        <div v-for="(menu, index) in menu" :key="index">
                            <p class="menu_name">
                               <i class="fas fa-book-open"></i>
                                <span>{{ menu.value.menu_name }}</span>
                            </p>
                            <p class="menu_content">
                                <span>{{ menu.value.price }}</span>
                            </p>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";
    import mapboxgl from "mapbox-gl";
    mapboxgl.accessToken = 'pk.eyJ1IjoidGVjaG5vZXllIiwiYSI6ImNsdDNsaWZoczF5OHEyanJvNXVsM2V3Z3EifQ.D4XO7xMtEWSBevi12Tr4Dg';
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        name: "SponsorDetail",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data() {
            return {
                isActive: 1,
                center: {
                    lat: 34.681705,
                    lng: 135.49655,
                },
                companyName: '',
                sponsor: [],
                coupon: [],
                menu: [],
                couponRecommendName: '',
            }
        },
        created() {
            //戻るボタンクリックしたときに戻るタブをセット
            if(this.$_Page() == 'SponsorList'){
                this.$_SetmixPC_ActiveTabIndex(3)
            }
        },
        mounted() {
            let self = this

            const map = new mapboxgl.Map({
                container: this.$refs.mapContainer,
                style: "mapbox://styles/mapbox/streets-v12",
                center: [135.501454, 34.693757],
                zoom: 14,
                attributionControl: false,
            });
            this.map = map
            // disable map rotation using right click + drag
            map.dragRotate.disable();
            // disable map rotation using touch rotation gesture
            map.touchZoomRotate.disableRotation()

            //日本語対応
            const MapboxLanguage = require('@mapbox/mapbox-gl-language')
            this.map.addControl(
                new MapboxLanguage({
                    defaultLanguage: 'ja'
                })
            );

            let data = {
                sponsor_id: this.$_Sponsor_id()
            }
            let sponsorDetail = this.$_mixAPI_getSponsorDetail(data)
            sponsorDetail.then(function(val){
                val.sponsor.forEach(function(value){
                    // Map上に現在地を表示するMarkerを作成
                    self.marker = new mapboxgl.Marker({
                        draggable: false,
                        anchor: "top-left"
                    })
                        .setLngLat([value.lng, value.lat])
                        .addTo(map)
                    // Mapの中心を現在地に移動
                    map.setCenter([value.lng, value.lat])

                    self.sponsor = value
                    self.companyName = value.company_name
                    self.couponRecommendName = value.coupon_recommend_name
                })
                let coupon_data = []
                val.coupon.forEach(function(value){
                    if(value.length == 0){
                        coupon_data = null
                        self.isActive = 2
                    }else{
                        let data = {
                            value
                        }
                        coupon_data.push(data)
                    }
                })
                self.coupon = coupon_data
                let menu_data = []
                val.menu.forEach(function(value){
                    if(value.length == 0){
                        menu_data = null
                    }else{
                        let data = {
                            value
                        }
                        menu_data.push(data)
                    }
                })
                self.menu = menu_data
            })
        },
        methods: {
            change: function(num){
                this.isActive = num
            }
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    img {
        width: 100%;
    }
    p {
        margin: 0;
    }
    .main-top {
        margin: 3% 3% 0 3%;
    }
    .main {
        color: white;
    }
    .tabs {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 3% 3% 0 3%;
    }
    .tab{
        width: 100%;
        padding: 5% 0 5% 0;
        background-color: gray;
        font-size: 80%;
        border: 1px solid #000;
        border-radius: 5px 5px 0 0;
    }
    .active {
        color: black;
        background-color: white;
        border-bottom: 2px solid #000;
    }
    .coupon {
        padding: 3%;
    }
    .coupon_main {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 1000px;
        background-color: #b99200;
        color: #000;
        font-family: MS Pゴシック;
        box-shadow: 0 0 8px #bfbfbf;
        margin: 2% 0 2% 0;
    }
    .left {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 12px 7px;
    }
    .left_cont {
        font-weight: 700;
        background-color: #fff;
        border-radius: 3px;
        padding: 10px 10px 18px 10px;
        line-height: 17px;
        letter-spacing: -0.2px;
        font-size: 15px;
        width: 85%;
    }
    .store{
        font-size: 11px;
        color: #666;
        margin-bottom: 5px;
        text-align: left;
    }
    .right {
        position: relative;
        display: flex;
        width: 100px;
        padding: 25px 15px;
        border-left: 2px dashed #f1f3f4;
        justify-content: center;
        align-items: center;
    }
    .right::before, .right::after {
        position: absolute;
        display: block;
        content: "";
        border-radius: 100%;
        background: #f1f3f4;
        left: -12px;
        width: 22px;
        height: 22px;
    }
    .right::after{
        bottom: -11px;
    }
    .right::before{
        top: -11px;
    }
    .contents{
        list-style-type: none;
        color: black;
        background-color: white;
        opacity: 1.0;
        padding: 0;
        margin: 0 3.5% 5% 3.5%;
        z-index: 1;
    }
    .coupon_contents {
        display: grid;
    }
    .coupon_contents_name {
        text-align: left;
        font-size: 14px;
        margin-top: 2%;
        display: grid;
        grid-template-columns: 18% 5% 77%;
    }
    .company_contents_title {
        background-color: #f1f3f4;
        text-align: left;
        padding: 2% 0 2% 2%;
    }
    .verticalLine {
        border-left: thick solid #b99200;
        padding-left: 10px;
    }
    .introduction {
        white-space: pre-wrap;
        text-align: left;
        font-size: 85%;
        line-height: 170%;
        padding: 2% 5% 2% 5%;
    }
    .address {
        text-align: left;
        font-size: 95%;
        padding: 3% 5% 1% 5%;
    }
    .access {
        text-align: left;
        font-size: 100%;
        padding: 3% 5% 3% 5%;
    }
    .tel {
        text-align: left;
        font-size: 100%;
        padding: 3% 5% 3% 5%;
    }
    .business_hours {
        text-align: left;
        font-size: 100%;
        padding: 3% 5% 3% 5%;
    }
    .regular_holiday {
        text-align: left;
        font-size: 100%;
        padding: 3% 5% 3% 5%;
    }
    .map {
        margin: 2%;
    }
    .map-container {
        width: 96%;
        height: 300px;
        margin: 2%;
    }
    .menu_name {
        font-family: "BIZ UDP明朝 Medium";
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #f1f3f4;
        text-align: left;
        padding: 2% 0% 2% 2%;
    }
    .menu_name i {
        color: #b99200;
    }
    .menu_content {
        font-family: "BIZ UDP明朝 Medium";
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 2% 0 2% 2%;
        text-align: left;
    }
    .shop_name {
        font-family: "BIZ UDP明朝 Medium";
        font-weight: bolder !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: white;
        font-size: 150%;
        margin: 0 0 2% 0;
    }
    .sns_icon{
        display: flex;
    }
    .sns_url {
        width: 50px;
        margin: 0 3px 0 3px;
    }
    .sns_icon {
        width: 35px;
        padding: 5px 5px 0 5px;
    }
    .sns_name {
        font-size: 10px;
        margin-bottom: 5px;
    }
</style>