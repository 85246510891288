<!--
  Title        : スポンサー一覧
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
    <v-ons-page>

        <div class="background"></div>
        <Toolbar :action="toggleMenu"></Toolbar>
        <BottomToolbar></BottomToolbar>

        <img v-show="banner" src="img/スポンサー募集.jpg" style="width: 100%">

        <div>
            <div class="card" @click="detail(sponsor.sponsor.sponsor_id)" v-for="(sponsor, index) in res" :key="index">
                <div class="shop">
                    <div class="small_category">
                        <img id="category_img" :src="category_img">
                        <p class="category_name_long" v-show="category_name == '焼き鳥・焼肉' || category_name == 'ファッション'">
                            {{ category_name }}
                        </p>
                        <p class="category_name" v-show="category_name != '焼き鳥・焼肉' && category_name != 'ファッション'">
                            {{ category_name }}
                        </p>
                    </div>
                    <div class="shop_name">
                        <p class="name">
                            {{ sponsor.sponsor.company_name }}
                        </p>
                    </div>
                </div>
                <p class="access">
                    <i class="fas fa-map-marker-alt" style="color: red; margin-right: 5px"></i>{{ sponsor.sponsor.access }}
                </p>
                <div class="shop_item">
                    <img class="shop_banner" :src="sponsor.sponsor.sponsor_img">
                    <div class="shop_contents">
                        <p class="shop_content_name">
                            <span>地域</span>
                            <b>：</b>
                            <b class="festival">{{ sponsor.sponsor.festival_name }}</b>
                        </p>
                        <p class="shop_content_name">
                            <span>カテゴリー</span>
                            <b>：</b>
                            <span>{{ sponsor.sponsor.small_category_name }}</span>
                        </p>
                        <p class="shop_content_name">
                            <span>営業時間</span>
                            <b>：</b>
                            <span>{{ sponsor.sponsor.business_hours }}</span>
                        </p>
                        <p class="shop_content_name">
                            <span>定休日</span>
                            <b>：</b>
                            <span>{{ sponsor.sponsor.regular_holiday }}</span>
                        </p>
                        <p class="coupon_mark_red" v-if="sponsor.coupon_flg == true">
                            <i class="fas fa-wallet"></i>
                            クーポン有り
                        </p>
                        <p class="coupon_mark_blue"  v-if="sponsor.coupon_flg == false">
                            <i class="fas fa-wallet"></i>
                            クーポン無し
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </v-ons-page>
</template>

<script>
    import Toolbar from "../Common/Toolbar";
    import BottomToolbar from "../Common/BottomToolbar";

    export default {
        name: "SponserList",
        props: ['toggleMenu'],
        components: {
            Toolbar,
            BottomToolbar,
        },
        data(){
            return{
                res: [],
                banner: false,
                category_img: null,
                category_name: null
            }
        },
        created(){
            let data = {
                small_category_id: this.$_CategoryID()
            }
            let sponsorlist = this.$_mixAPI_getSponsorList(data)
            let self = this
            sponsorlist.then(function(val){
                let coupon_flg = ''
                let rtndata = [];
                val.forEach(function(value){
                    value.coupon > 0 ? coupon_flg = true : coupon_flg = false
                    let data = {
                        coupon_flg: coupon_flg,
                        sponsor: value.sponsor
                    }
                    self.category_name = value.small_category_name
                    self.category_img = value.small_category
                    rtndata.push(data)
                })
                self.res = rtndata
            }).catch((err)=>{   //通信エラー時
                console.log(err)
                this.banner = true
            }).finally(()=>{
            });
        },
        methods: {
            detail(val) {
                this.$_setSponsor_id(val)
                this.$_setPage('')
                return(
                    // this.$_setCategoryID(val),
                    //Key（文字列）を検索し、Pageコンポーネントをカレントへセットする
                    this.$_SetmixPC_CurrentPage_Key('sponsorDetail')
                );
            }
        }
    }
</script>

<style scoped>
    .background {
        background-image: url("/img/bg_sayagata.jpg");
    }
    .card {
        margin: 3% 2% 2% 2%;
        padding: 2%;
        border-radius: 0;
    }
    .shop {
        display: flex;
    }
    .access {
        margin: 3px 0 0 3px;
        font-size: 70%;
        color: #9e9e9e;
    }
    #category_img{
        width: 20px;
        height: 20px;
        margin: 0 0 5px 3px;
    }
    .small_category {
        width: 35%;
        height: 25px;
        font-size: 12px;
        border: 1px solid #000;
    }
    .category_name {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 20px;
        font-family: "BIZ UDP明朝 Medium"
    }
    .category_name_long {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 20px;
        font-size: 80%;
        font-family: "BIZ UDP明朝 Medium"
    }
    .shop_banner {
        width: 95%
    }
    .shop_name {
        font-weight: bold;
        font-size: 90%;
        width: 100%;
        margin-left: 3%;
        background: linear-gradient(to right, #f79b05, #fff);
        border-left: 6px solid #f79b05;
        font-family: "BIZ UDP明朝 Medium"
    }
    .name {
        margin: 5px 0 0 5px;
    }
    .shop_item {
        display: grid;
        grid-template-columns: 43% 1fr;
    }
    .shop_content_name {
        width: 200px;
        font-size: 12px;
        margin-bottom: 2%;
        display: grid;
        grid-template-columns: 60px 15px 125px;
    }
    *,  p {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
    }
    .coupon_mark_red {
        color: #d40000;
        text-align: center;
        border: 1px solid #d40000;
        border-radius: 20px;
        width: 60%;
        padding: 2%;
        font-size: 65%;
        margin-top: 5%;
    }
    .coupon_mark_blue {
        color: #2c3e50;
        text-align: center;
        border: 1px solid #2c3e50;
        border-radius: 20px;
        width: 60%;
        padding: 2%;
        font-size: 65%;
        margin-top: 5%;
    }
    .festival {
        font-size: 100%;
    }
</style>