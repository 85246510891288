<!--
  Title        : 町マップ
  Author       : 川端優瑠
  Version      : 1.0.0
  Creation Day : 2023/01/04
  Update Day   : 2023/01/04
-->

<template>
  <v-ons-page>
    <div class="background"></div>
    <div id="googlemap"
         class="top_part"
         :class="{iPhoneMap:terminal === 'iPhone', AndroidMap:terminal === 'Android'}"
         @touchmove.passive.stop=""
         scroll.passive.stop="">
      <GmapMap
              :center='center'
              :zoom="16"
              map-type-id="roadmap"
              style="width: 100%; height: 100%"
              :options="{
           zoomControl: true,
           mapTypeControl: false,
           scaleControl: false,
           streetViewControl: false,
           rotateControl: true,
           fullscreenControl: false,
           disableDefaultUI: false,
           gestureHandling: 'greedy',
           keyboardShortcuts: false
        }"
      >
        <!--現在地-->
        <GmapMarker
                :key="markSelf.id"
                :position="markSelf.position"
                :clickable="false"
                :draggable="false"
                :icon="markSelf.icon"
                :visible="markSelf.visible"
        />
        <!--地車-->
        <GmapMarker
                :key="markDanjiri.id"
                :position="markDanjiri.position"
                :clickable="false"
                :draggable="false"
                :icon="markDanjiri.icon"
                :visible="markDanjiri.visible"
        />
        <!--小屋-->
        <GmapMarker
                :position="hutData.position"
                :clickable="false"
                :draggable="false"
                :icon="hutData.icon"
                :visible="hutVisible"
        />
        <!--施設-->
        <GmapMarker
                :key="index"
                v-for="(Facility, index) in Facilities"
                :position="Facility.position"
                :clickable="false"
                :draggable="false"
                :icon="FacilityIcons[Facility.category]"
                :visible="FacilityVisible"
        />
      </GmapMap>
    </div>

    <div class="lower_part" :class="{iPhoneContent:terminal === 'iPhone', AndroidContent:terminal === 'Android'}">
      <div class="content">
        <div id="content">
          <div class="button" @click="hutPush()">
            <div style="padding: 1%">
              <div class="hut">
                <v-ons-icon id="hut" icon="md-home" size="40px"></v-ons-icon>
              </div>
            </div>
          </div>
        </div>

        <div  id="content">
          <div class="button" @click="FacilityPush()">
            <div style="padding: 1%">
              <div class="temporaryToilet">
                <v-ons-icon id="male" icon="md-male-alt" size="40px"></v-ons-icon>
                <v-ons-icon id="female" icon="md-female" size="40px"></v-ons-icon>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </v-ons-page>
</template>

<script>
export default {
  name: "Map",
  data() {
    return{
      device_id: 0,
      device_active: 0,

      center: {
        lat: 0,
        lng: 0,
      },
      markSelf: {
        id:0,
        position:{ lat:0, lng:0 },
        icon:{
          url: '/img/current_location.png',
          scaledSize: { width: 40, height: 60, f: 'px', b: 'px' },
        },
        visible:false
      },
      markDanjiri: {},
      markAllDanjiri: [],
      intervalID: undefined,
      setpPotionTodanjiri: true,

      hutData: {},
      hutVisible: false,

      Facilities: [],
      FacilityVisible: false,
      FacilityIcons: {
        1: {
          url: '/img/map_icon/トイレ.png',
          scaledSize: { width: 25, height: 25, f: 'px', b: 'px' }
        },
        2: {
          url: 'img/map_icon/ごみ箱.png',
          scaledSize: { width: 25, height: 25, f: 'px', b: 'px' }
        },
        3: {
          url: 'img/map_icon/AED.png',
          scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
        },
        4: {
          url: 'img/map_icon/監視塔.png',
          scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
        },
        5: {
          url: 'img/map_icon/救護.png',
          scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
        },
        6: {
          url: 'img/map_icon/本部.png',
          scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
        },
        7: {
          url: 'img/map_icon/通行止め.png',
          scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
        },
        8: {
          url: 'img/map_icon/駐車場.png',
          scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
        },
        9: {
          url: 'img/map_icon/駐輪場.png',
          scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
        },
        10: {
          url: 'img/map_icon/喫煙所.png',
          scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
        },
      },
      terminal: this.$_Terminal(),
    }
  },
  mounted() {
    let self = this

    let api = {
      town_id: this.$_TownID()
    }
    let getResultAPI = this.$_mixAPI_townGPS(api)
    getResultAPI.then(function(val){
      self.device_id = val.device_id
      self.device_active = val.device_active
    })

    this.center.lat = parseFloat(this.$_FestivalData().festival_latitude)
    this.center.lng = parseFloat(this.$_FestivalData().festival_longitude)

    //広域変数からデータを取得
    this.townData = this.$_TownData();

    //フォーカス位置をだんじりへ
    this.setpPotionTodanjiri = true

    //現在位置セット
    if (navigator.geolocation) {
      let vm = this
      navigator.geolocation.getCurrentPosition(
          function(position){
            vm.center.lat = position.coords.latitude
            vm.center.lng = position.coords.longitude
            //現在位置をマーク
            vm.markSelf.visible = true
            vm.markSelf.position = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          },function error(msg){
            console.log(msg);
          }, {enableHighAccuracy: true}
      );
      navigator.geolocation.watchPosition(function (position){
        //現在位置をマーク
        vm.markSelf.position.lat = position.coords.latitude
        vm.markSelf.position.lng = position.coords.longitude
      });
    }

    this.markDanjiri = {
      id: this.townData.town_id,
      position:{
        lat:0,lng:0
      },
      icon:{
        url: this.townData.town_icon,
        scaledSize: { width: 60, height: 60, f: 'px', b: 'px' },
      },
      visible: false
    }
    this.hutData = {
      id: this.$_TownData.town_id,
      position:{
        lat:parseFloat(this.$_TownData().storehouse_latitude),
        lng:parseFloat(this.$_TownData().storehouse_longitude)
      },
      icon:{
        url:this.$_TownData().hut_img,
        scaledSize: { width: 50, height: 50, f: 'px', b: 'px'}
      }
    }

    let apiVal = {
      festival_id: this.$_festivalIDS()
    }
    let getData = this.$_mixAPI_testMapIcon(apiVal)
    getData.then(function(val){
      val.forEach((value) => {
        let tmpFacility = {}
        tmpFacility.id = value.temporarytoilet_id
        tmpFacility.position = {
          lat: parseFloat(value.location_latitude),
          lng: parseFloat(value.location_longitude)
        }
        tmpFacility.category = value.category_flg // カテゴリー情報を追加
        self.Facilities.push(tmpFacility)
      })
    })

    this.intervalID = setInterval(() => {
      let apiVal = {
        town_id: this.$_TownID()
      }
      let getResultAPI = this.$_mixAPI_townGPS(apiVal)
      getResultAPI.then(function(val){
        self.device_id = val.device_id
        self.device_active = val.device_active
      })
    },300000)

    //GPS情報をGET
    this.intervalID = setInterval(() => {
      let self = this
      if(this.device_active == 1){
        let apiVal = {
          device_num: this.device_id
        }
        let getResultAPI = this.$_mixAPI_GPS_getLatLng(apiVal)
        getResultAPI.then(function(val){
          let parVal = JSON.parse(val.body)
          if (parVal.length !== 0) {
            //だんじりマーカー設置
            self.markDanjiri.visible = true
            self.markDanjiri.position = {
              lat: parseFloat(parVal[0].lat),
              lng: parseFloat(parVal[0].long)
            }
            if (self.setpPotionTodanjiri){
              //センターをだんじりマーカへ
              self.center = {
                lat: parseFloat(parVal[0].lat),
                lng: parseFloat(parVal[0].long)
              }
              self.setpPotionTodanjiri = true
            }
          } else {
            self.markDanjiri.visible = false
          }
        }).catch((err)=>{   //通信エラー時
          console.log(err);
        })
      }else{
        self.markDanjiri.visible = false
      }
    },5000)
  },
  methods:{
    FacilityPush() {
      this.FacilityVisible = !this.FacilityVisible;
    },
    hutPush() {
      this.hutVisible = !this.hutVisible;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalID)
  }
}
</script>

<style scoped>
  .top_part{
    width: 100%;
    height: calc(100% - 70px);
    margin: 0;
  }
  .lower_part{
    background-color: #2b2b2b;
    width: 100%;
    height: 70px;
    margin: 0;
  }
  .iPhoneMap{
    width: 100%;
    height: calc(100% - 70px);
    margin: 0;
  }
  .iPhoneContent{
    background-color: #313131;
    width: 100%;
    height: 70px;
    margin: 0;
  }
  .AndroidMap{
    width: 100%;
    height: calc(100% - 85px);
    margin: 0;
  }
  .AndroidContent{
    background-color: #313131;
    width: 100%;
    height: 85px;
    margin: 0;
  }
  #hut{
    color: white;
  }
  #male{
    color: white;
  }
  #female{
    color: white;
    margin-left: 5%;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  #content{
    flex-grow: 1;
    position: relative;
    width: 50%;
    height: 50px;
    margin: 10px 0 10px 0;
  }
  .button {
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
    background: linear-gradient(#16160e, #383c3c 50%, #16160e);
    box-shadow: 0 3px 0 #000;
    border-radius: 2rem;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }
  .button span {
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }
  .button:hover {
    background-color: #404040;
    box-shadow: 0 3px 0 #000;
  }
  .button:active {
    box-shadow: 0 1px 0 #000;
    transform: translateY(2px);
  }
</style>
